<!--
 * @Description: 客服用户服务管理 信息管理 咨询记录管理 consultRemarkManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:32:35
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="记录标题">
              <el-input v-model="searchForm.advisoryRecordTitle"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="记录状态">
              <el-select v-model="searchForm.advisoryStatusCode"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in handleList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="记录类型">
              <el-select v-model="searchForm.advisoryType"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="创建时间">
              <el-date-picker v-model="noticeDate"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="search">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>

        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>咨询管理</span>
        <el-button class="tableTitleButton"
                   type="success"
                   @click="addHandle">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="detailsClick(scope.row)">详情</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="editClick(scope.row)">修改</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             @click="deleteClick(scope.row)">删除</el-button>
                </el-col>
                <el-col :span="6">
                  <el-button type="text"
                             size="medium"
                             v-show="scope.row.advisoryStatusDesc === '未处理'"
                             @click="publisClick(scope.row)">处理</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增表单 -->
    <el-dialog title="新增咨询"
               :visible.sync="instreVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <el-form label-width="150px"
               :model="instreForm"
               :label-position="labelPosition"
               :rules="rules"
               ref="instreForm"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="记录标题："
                          prop="advisoryRecordTitle">
              <el-input v-model="instreForm.advisoryRecordTitle"
                        class="dt-form-width"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录类型："
                          prop="advisoryType">
              <el-select v-model="instreForm.advisoryType"
                         class="dt-form-width"
                         clearable
                         placeholder="">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="记录内容："
                        prop="content">
            <el-input type="textarea"
                      style="width:85%;"
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="instreForm.content"></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       @click="insterSubmit('instreForm')"
                       style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">保 存</el-button>
            <el-button @click="instreVisible = false"
                       type="info">取 消</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 修改 -->
    <el-dialog title="修改咨询"
               :visible.sync="upDateVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="popClose">
      <el-form label-width="150px"
               :model="upDateForm"
               :label-position="labelPosition"
               :rules="rules"
               ref="instreForm"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="记录标题："
                          prop="advisoryRecordTitle">
              <el-input v-model="upDateForm.advisoryRecordTitle"
                        class="dt-form-width"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录类型："
                          prop="advisoryType">
              <el-select v-model="upDateForm.advisoryType"
                         class="dt-form-width"
                         clearable
                         placeholder="">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="记录内容："
                        prop="content">
            <el-input type="textarea"
                      style="width:85%;"
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="upDateForm.content"></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       @click="upDateSubmit('instreForm')"
                       :disabled="disabled"
                       style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">保 存</el-button>
            <el-button @click="upDateVisible = false"
                       type="info">取 消</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="咨询详情"
               :visible.sync="detailsVisible"
               append-to-body
               @close="popClose">
      <el-form :model="detailsForm"
               label-width="150px"
               :label-position="labelPosition"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="记录标题：">
              <el-input v-model="detailsForm.advisoryRecordTitle"
                        readonly
                        style="width:63%"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录类型：">
              <el-select v-model="detailsForm.advisoryType"
                         style="width:63%"
                         disabled
                         placeholder="">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="记录内容：">
            <el-input type="textarea"
                      style="width:85%;"
                      readonly
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="detailsForm.content"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否处理：">
              <el-select v-model="detailsForm.advisoryStatusCode"
                         style="width:63%"
                         disabled
                         placeholder="">
                <el-option v-for="item in handleList"
                           :key="Number(item.code)"
                           :label="item.name"
                           :value="Number(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="处理备注：">
            <el-input type="textarea"
                      style="width:85%;"
                      readonly
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="detailsForm.handleRemark"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 处理 -->
    <el-dialog title="咨询处理"
               :visible.sync="dealVisible"
               append-to-body
               @close="popClose"
               :close-on-click-modal="false">
      <el-form label-width="150px"
               :model="dealForm"
               :label-position="labelPosition"
               :rules="rules"
               ref="dealForm"
               size="small">
        <el-row>
          <el-form-item label="处理描述："
                        prop="handleRemark">
            <el-input type="textarea"
                      style="width:95%;"
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="dealForm.handleRemark"></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       @click="dealSubmit('dealForm')"
                       style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">保 存</el-button>
            <el-button @click="dealVisible = false"
                       type="info">取 消</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'advisoryRecordTitle',
          label: '记录标题',
        },
        {
          prop: 'advisoryTypeDesc',
          label: '记录类型',
        },
        {
          prop: 'content',
          label: '记录内容',
        },
        {
          prop: 'advisoryStatusDesc',
          label: '记录状态',
        },
        {
          prop: 'createTime',
          label: '记录时间',
        },
      ],
      tableList: {
        list: [],
      },
      searchForm: {
        title: '',
        status: '',
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 15
      },
      typeList: [],
      handleList: [],
      noticeDate: [],
      instreForm: {},//新增form
      upDateForm: {},//修改form
      detailsForm: {},//详情form
      dealForm: {},//处理form
      labelPosition: 'left',//对齐方向
      disabled: false, //禁用开关
      rules: {
        advisoryRecordTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        advisoryType: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        handleRemark: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      // 日历快捷
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      instreVisible: false,//新增弹出框开关
      upDateVisible: false,//修改弹窗开关
      detailsVisible: false,//详情弹窗开关
      dealVisible: false,//处理弹出窗开关
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getMenu()
    this.getConsulted()
    this.getHandle()
  },
  //方法集合
  methods: {
    //获取咨询类型
    getConsulted () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '2D8AC5EA17224F933R453FF183E309E6',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.typeList = res.resultEntity
      })
    },
    //是否处理查询
    getHandle () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABA91DB035D54E3F',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.handleList = res.resultEntity
      })
    },

    //查询
    getMenu () {
      this.$consultRemarkManage.queryAdvisoryRecordList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //获取列表
    getList (map = { pageNum: 1, pageSize: 15 }) {
      this.searchForm = map
      this.getMenu()
    },
    //查询
    search () {
      this.searchForm.startTime = this.noticeDate[0]
      this.searchForm.endTime = this.noticeDate[1]
      this.getMenu()
    },
    //新增
    addHandle () {
      this.instreVisible = true
    },
    //修改
    editClick (row) {
      this.upDateForm = this.$deepClone(row)
      this.upDateVisible = true
    },
    //新增提交按钮
    insterSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$consultRemarkManage.addAdvisoryRecordt(this.instreForm).then(() => {
            this.$message({ message: '新增成功', type: 'success' });
            this.getMenu()
            this.instreVisible = false
          })
        } else {
          return false;
        }
      })
    },
    //修改提交按钮
    upDateSubmit (formName) {
      this.disabled = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$consultRemarkManage.updateAdvisoryRecord(this.upDateForm).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
            this.getMenu()
            this.upDateVisible = false
          })
        } else {
          return false;
        }
      });
      setTimeout(() => {
        this.disabled = false;
      }, 1000)
    },

    //处理提交
    dealSubmit () {
      this.$consultRemarkManage.handleAdvisoryRecord(this.dealForm).then(() => {
        this.$message({ message: '处理成功', type: 'success' });
        this.getMenu()
        this.dealVisible = false
      })
    },
    // 删除请求
    deleteClick (row) {
      console.log(row)
      this.$confirm('是否删除咨询?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = [{ advisoryRecordId: row.advisoryRecordId }]
        this.$consultRemarkManage.deleteAdvisoryRecord(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.getMenu()
          }
        })
      })
    },
    // 处理按钮
    publisClick (row) {
      this.dealForm.advisoryRecordId = row.advisoryRecordId
      this.dealVisible = true
    },
    // 详情
    detailsClick (row) {
      this.detailsVisible = true
      this.detailsForm = row
    },

    //重置
    resetForm () {
      this.searchForm = {
        title: '',
        status: '',
        startDate: '',
        endDate: '',
        pageNum: 1,
        pageSize: 15
      }
      this.noticeDate = []
      this.getMenu()
    },
    //弹出框关闭
    popClose () {
      this.instreForm = {}
      this.$refs.instreForm.resetFields();
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 63%;
}
</style>